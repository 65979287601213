import { Typography } from '@sicpama/core-components';
import React from 'react';

interface ProviderLoginButtonProps {
  readonly onClick: () => void;
  readonly iconSrc?: string;
  readonly buttonText: string;
  readonly color: string;
  readonly textColor?: string;
  readonly customStyle?: React.CSSProperties;
}

function ProviderLoginButton({
  onClick,
  iconSrc,
  buttonText,
  color,
  textColor = 'text-black',
  customStyle,
}: ProviderLoginButtonProps): JSX.Element {
  return (
    <div
      className={`btn flex gap-2 items-center justify-center ${color} ${textColor} w-full px-6 py-4 rounded mt-3`}
      onClick={onClick}
      style={customStyle || {}}
    >
      <div className="flex items-center gap-2 justify-between w-[220px]">
        {iconSrc && <img className="w-6 h-6 shrink-0" src={iconSrc} width={24} height={24} />}
        <Typography weight={700} className="w-full text-center">
          {buttonText}
        </Typography>
      </div>
    </div>
  );
}

export default ProviderLoginButton;
