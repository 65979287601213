var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Accordion as MantineAccordion } from '@mantine/core';
var Accordion = function (_a) {
    var items = _a.items, toggleIcon = _a.toggleIcon, toggleIconPosition = _a.toggleIconPosition, toggleIconSize = _a.toggleIconSize, openedMultiple = _a.openedMultiple, openedDefault = _a.openedDefault, onChange = _a.onChange, value = _a.value, type = _a.type, rest = __rest(_a, ["items", "toggleIcon", "toggleIconPosition", "toggleIconSize", "openedMultiple", "openedDefault", "onChange", "value", "type"]);
    return (React.createElement(MantineAccordion, __assign({ chevron: toggleIcon, chevronPosition: toggleIconPosition, chevronSize: toggleIconSize, multiple: openedMultiple, defaultValue: openedDefault, variant: type, value: value, onChange: onChange }, rest), items.map(function (item) {
        var title = item.title, content = item.content, value = item.value, disabled = item.disabled, leftIcon = item.leftIcon, remainingProps = __rest(item, ["title", "content", "value", "disabled", "leftIcon"]);
        return (React.createElement(MantineAccordion.Item, __assign({ key: value, value: value }, remainingProps),
            React.createElement(MantineAccordion.Control, { icon: leftIcon, disabled: disabled }, title),
            React.createElement(MantineAccordion.Panel, null, content)));
    })));
};
export default React.memo(Accordion);
