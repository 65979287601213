import { Modal, Typography } from '@sicpama/core-components';
import { useOrderStore, useThemeStore } from 'stores';
import AvailableCouponModalItem from './AvailableCouponModalItem';
import { useTranslation } from 'react-i18next';
import EarnedStampsModal from 'components/earned-stamps-modal';

interface AvailableCouponModalProps {
  readonly opened: boolean;
  readonly closeModal: () => void;
}

function AvailableCouponModal({ opened, closeModal }: AvailableCouponModalProps): JSX.Element {
  const { t } = useTranslation();
  const { coupons, stampCoupon, myOrder } = useOrderStore();
  const { store } = useThemeStore();

  const storeStampCoupon = store?.coupon?.find((x) =>
    x.criteria.customer?.some((y) => y.stamps !== undefined),
  );

  const finalStampCoupon = stampCoupon || storeStampCoupon;

  const availableCouponIds = coupons
    .map((coupon) => coupon.id)
    .filter((id) => id !== stampCoupon?.id);

  // TODO: Prioritize display stamp modal first
  if (finalStampCoupon) {
    return (
      <EarnedStampsModal
        opened={opened}
        closeModal={closeModal}
        store={store}
        myOrder={myOrder}
        stampCoupon={finalStampCoupon}
      />
    );
  }

  return (
    <Modal opened={opened} onClose={closeModal} centered>
      <div className="w-full flex flex-col px-4 py-2">
        <div className="w-full flex flex-row">
          <Typography weight="bold" size="lg">
            {t('coupons.myCoupons')}
          </Typography>
        </div>
        <div className="flex flex-col overflow-y-auto px-8">
          {availableCouponIds.map((couponId, idx) => (
            <AvailableCouponModalItem
              key={couponId}
              id={couponId}
              separator={idx < availableCouponIds.length - 1}
            />
          ))}
        </div>
        <div className="w-full flex flex-row justify-end">
          <button className="text-yellow-500" onClick={closeModal}>
            {t('coupons.confirm')}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AvailableCouponModal;
