import { Button } from '@sicpama/core-components';
import { useTranslation } from 'react-i18next';
import { Sheet } from 'react-modal-sheet';
import { getCouponDesign } from 'utils/coupons';
import { StoreResDto } from 'types';
import { ICouponResDto, ITableOrderItemResDto } from '../../submodules/sicpama-shared';

interface EarnedStampsModalProps {
  readonly opened: boolean;
  readonly closeModal: () => void;
  store: StoreResDto;
  myOrder: ITableOrderItemResDto;
  stampCoupon: ICouponResDto;
  values?: Record<string, string>;
  t?: any;
}

function EarnedStampsModal({
  opened,
  closeModal,
  store,
  myOrder,
  stampCoupon,
  values,
}: EarnedStampsModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Sheet isOpen={opened} onClose={closeModal} detent="content-height">
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content>
          <div className="mx-4">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  getCouponDesign({
                    coupon: stampCoupon,
                    store,
                    values: {
                      heading1: `${t('coupons.yourEarnedStamp')}`,
                      heading2: '',
                      ...values,
                    },
                    earnedStamps: myOrder?.stampCount ?? 0,
                    currentNumberOfCoupons: myOrder?.coupons?.length ?? 0,
                  }) || `<div></div>`,
              }}
            ></div>
            <Button
              className="w-full bg-theme my-4"
              size="lg"
              title={t('coupons.confirm')}
              onClick={closeModal}
              titleColor="white"
            />
          </div>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}

export default EarnedStampsModal;
