import { Modal, Typography } from '@sicpama/core-components';
import DiscountCarousel from 'pages/menu/DiscountCarousel';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PublicContainer } from '../../../components';
import Divider from '../../../components/divider';
import {
  storeThemeSelectors,
  useAuthStore,
  useMenuStore,
  useOrderStore,
  useThemeStore,
} from '../../../stores';

import '../../login/styles.scss';
import CouponHeader from './CouponHeader';
import DefaultHeader from './DefaultHeader';
import { PROVIDER_NAME } from 'enums/authProvider';
import ProviderLoginButton from './ProviderLoginButton';
import { ADVERT_LOCATION } from '../../../submodules/sicpama-shared';

const translationPrefix = 'pages.login';

const LoginModal = (): JSX.Element => {
  const navigate = useNavigate();
  const { isLoading, goToProviderIdSignInUrl } = useAuthStore();
  const { myInfo } = useOrderStore();
  const { t } = useTranslation();
  const { getMenus } = useMenuStore();

  const coupons = useThemeStore(
    storeThemeSelectors.getCouponByAdvertLocation(ADVERT_LOCATION.LOGIN_PAGE),
  );

  const isCouponAvailable = coupons.length > 0;

  const isCookiesEnabled = navigator.cookieEnabled;

  const onProviderClick = (provider?: PROVIDER_NAME): void => {
    if (isLoading) {
      return;
    }

    if (!provider) {
      navigate('/menus');
      return;
    }
    goToProviderIdSignInUrl(provider);
  };

  useEffect(() => {
    getMenus();
  }, []);

  useEffect(() => {
    if (myInfo?.sicpamaId) {
      navigate('/menus');
    }
  }, [myInfo]);

  return (
    <Modal
      className="my-login-modal"
      opened={true}
      onClose={() => {
        navigate('/menus');
      }}
      fullScreen
      title=""
      styles={() => ({
        body: {
          height: '100%',
          overflowY: 'auto',
          padding: 0,
        },
        title: {
          visibility: 'hidden',
        },
      })}
    >
      <PublicContainer showSearch={true} customClassName="relative h-full w-full">
        <div className="absolute z-[100] inset-0 opacity-70 bg-black" />
        <DiscountCarousel />
        <Divider className="!min-h-[8px] !h-[8px] my-2" />
      </PublicContainer>
      <div className="absolute z-[1000] bottom-0 left-0 right-0 h-auto bg-white rounded-t-lg">
        <div className="relative h-full w-full flex flex-col items-center p-8 !pb-10">
          {isCouponAvailable && <CouponHeader />}
          {!isCouponAvailable && <DefaultHeader translationPrefix={translationPrefix} />}
          <ProviderLoginButton
            onClick={() => {
              if (!isCookiesEnabled) {
                return;
              }
              onProviderClick(PROVIDER_NAME.GOOGLE);
            }}
            iconSrc="/icons/google-icon.svg"
            buttonText={t(`${translationPrefix}.buttons.google`)}
            color={isCookiesEnabled ? 'bg-white' : 'bg-[#bcbcbc]'}
            customStyle={{
              boxShadow: '2px 4px 32px 0px rgba(0, 0, 0, 0.04)',
              border: '1px solid rgba(0, 0, 0, 0.16)',
            }}
          />
          <ProviderLoginButton
            onClick={() => {
              if (!isCookiesEnabled) {
                return;
              }
              onProviderClick(PROVIDER_NAME.FACEBOOK);
            }}
            iconSrc="/icons/facebook-icon.svg"
            buttonText={t(`${translationPrefix}.buttons.facebook`)}
            color={isCookiesEnabled ? 'bg-[#1877F2]' : 'bg-[#bcbcbc]'}
            textColor="text-white"
          />
          <ProviderLoginButton
            onClick={() => {
              if (!isCookiesEnabled) {
                return;
              }
              onProviderClick(PROVIDER_NAME.KAKAO);
            }}
            iconSrc="/icons/kakao-icon.svg"
            buttonText={t(`${translationPrefix}.buttons.kakao`)}
            color={isCookiesEnabled ? 'bg-[#FEE500]' : 'bg-[#bcbcbc]'}
          />

          {!isCookiesEnabled && (
            <Typography variant="text" color="red" weight="bold" size="lg" className="mt-4">
              {t(`common.enableCookies`)}
            </Typography>
          )}

          <ProviderLoginButton
            onClick={() => {
              onProviderClick();
            }}
            buttonText={t(`${translationPrefix}.buttons.guest`)}
            color="bg-[#F0F0F0]"
          />
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
