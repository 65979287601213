import { Typography } from '@sicpama/core-components';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface DefaultHeaderProps {
  readonly translationPrefix: string;
}

function DefaultHeader({ translationPrefix }: DefaultHeaderProps): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onNavigate = (): void => {
    navigate('/menus');
  };

  return (
    <div>
      <div
        className="w-8 h-8 rounded-full absolute top-4 right-4 flex items-center justify-center"
        style={{ background: 'rgba(0, 0, 0, 0.05)' }}
      >
        <div onClick={onNavigate} className="cursor-pointer">
          <img className="w-[18px] h-[18px]" src="/icons/close-icon.svg" width={18} height={18} />
        </div>
      </div>
      <Typography
        weight={800}
        size={26}
        className={`${i18n.language === 'kr' ? 'text-[#FC4B1E]' : 'text-[#212121]'} text-center`}
        style={{ lineHeight: 1 }}
      >
        {t(`${translationPrefix}.title1`)}
      </Typography>
      <Typography
        weight={800}
        size={26}
        className={`${
          i18n.language === 'kr' ? 'text-[#212121]' : 'text-[#FC4B1E]'
        } text-center mt-2`}
        style={{ lineHeight: 1 }}
      >
        {t(`${translationPrefix}.title2`)}
      </Typography>
    </div>
  );
}

export default DefaultHeader;
