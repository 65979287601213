import { StoreResDto } from '../types';
import BaseHttpService from './base-http.service';
import { LOCAL_STORAGE_KEY } from 'constants/browser-storage.constant';

const PATH = 'consumer-stores';

class StoreService extends BaseHttpService {
  async getStoreData(): Promise<StoreResDto> {
    const body = await this.get<StoreResDto>(`${PATH}`);
    return body;
  }

  async getStoreById(storeId: number): Promise<StoreResDto> {
    const body = await this.get<StoreResDto>(`${PATH}/${storeId}`);
    return body;
  }

  async createVirtualTable(storeId: number): Promise<{ storeId: number; token: string }> {
    const requestId = localStorage.getItem(LOCAL_STORAGE_KEY.FINGERPRINT_REQUEST_ID);
    const response = await this.post<{ storeId: number; token: string }>(
      `${PATH}/${storeId}/virtual-tables`,
      {},
      {
        headers: {
          'fingerprint-request-id': requestId,
        },
      },
    );
    localStorage.removeItem(LOCAL_STORAGE_KEY.MY_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.MY_CURRENT_ORDER_ID);
    localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_TOKEN);
    return response;
  }
}

export const storeService = new StoreService();
