import { menuSelectors, useMenuStore } from 'stores';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';

interface RecommendedItemProps {
  readonly id: number;
  readonly onClick: () => void;
}

export default function RecommendedItem({ id, onClick }: RecommendedItemProps): JSX.Element {
  const baseMenu = useMenuStore(menuSelectors.getBaseMenuById(id));
  return (
    <div onClick={onClick} className="flex flex-col w-[120px] w-max-[120px] ml-[16px] mr-[2px]">
      <img
        src={baseMenu.photo ?? `${process.env.PUBLIC_URL}/images/no-photo.png`}
        className="w-[120px] h-[120px] rounded-lg"
      />
      <p className="w-[120px] pt-2 text-gray-600">{baseMenu.name}</p>
      <p>{numberLocaleFormat(+baseMenu.price, CURRENCY_TO_LOCALE_PRICE[baseMenu.currency])}</p>
    </div>
  );
}
