import { Grid } from '@sicpama/core-components';
import MultiLinesText from 'components/multi-lines-text';
import { ReactElement } from 'react';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';
import { IBaseOrderItemResDto } from '../../../submodules/sicpama-shared';

interface CompleteOrderListItemProps {
  readonly item: IBaseOrderItemResDto;
}

const CompleteOrderListItem = ({ item }: CompleteOrderListItemProps): ReactElement => {
  return (
    <div className="mb-2" key={item.id}>
      <Grid spans={[3, 3, 3, 3]}>
        <div className="ml-4 text-left">
          <MultiLinesText text={item?.menu?.name || ''} />
        </div>
        <p className="text-right">
          {numberLocaleFormat(
            item?.menu?.isTodayDiscounted && item?.menu?.discountPrice
              ? +item?.menu?.discountPrice
              : +item.menu.price,
            CURRENCY_TO_LOCALE_PRICE[item?.menu?.currency],
          )}
        </p>
        <p className="text-center">{item?.menu?.quantity}</p>
        <p className="pr-4 text-right">
          {numberLocaleFormat(+item.totalPrice, CURRENCY_TO_LOCALE_PRICE[item?.menu?.currency])}
        </p>
      </Grid>
    </div>
  );
};

export default CompleteOrderListItem;
