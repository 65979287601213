import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Sheet } from 'react-modal-sheet';
import { useNavigate, useParams } from 'react-router-dom';
import { menuSelectors, OrderSelectors, useMenuStore, useOrderStore } from 'stores';
import { getMenuRecommendedIds } from './RecommendationSystem';
import RecommendedItem from './RecommendedItem';
import { IMenuDetailsResDto } from '../../../submodules/sicpama-shared';

interface UseMenuRecommendationProps {
  readonly recommendedIds: number[];
  readonly menu: IMenuDetailsResDto | undefined;
  readonly onGoToMenu: () => void;
  readonly onGoToTab: () => void;
  readonly onRecommendedClick: (id: number) => () => Promise<void>;
}

function useRecommendationVM(onClose: () => void): UseMenuRecommendationProps {
  const { id } = useParams();
  const navigate = useNavigate();
  const menu = useMenuStore(menuSelectors.getMenuById(id ?? -1));
  const currentOrderItems = useOrderStore(OrderSelectors.getCurrentOrderItems);
  const baseMenuIds = useMenuStore(menuSelectors.getBaseMenuIds);
  const recommendationSystemList = useMenuStore(
    menuSelectors.getRecommendationSystemList(id ?? -1),
  );
  const recommendedIds = useMemo(
    () => getMenuRecommendedIds(currentOrderItems, recommendationSystemList, baseMenuIds),
    [currentOrderItems, recommendationSystemList],
  );
  const onRecommendedClick = useCallback(
    (id: number) => async () => {
      onClose();
      navigate(`/menus/${id}`);
    },
    [],
  );

  const onGoToTab = useCallback(() => {
    navigate('/tab');
  }, []);

  const onGoToMenu = useCallback(() => {
    navigate('/menus');
  }, []);

  return {
    menu,
    onGoToMenu,
    onGoToTab,
    onRecommendedClick,
    recommendedIds,
  };
}

interface RecommendationSheetProps {
  readonly open: boolean;
  readonly onClose: () => void;
}

export default function RecommendationSheet({
  open,
  onClose,
}: RecommendationSheetProps): JSX.Element {
  const { t } = useTranslation();
  const vm = useRecommendationVM(onClose);

  if (vm.menu === undefined) {
    return <></>;
  }

  return (
    <Sheet snapPoints={[400, 200, 0]} isOpen={open} onClose={onClose}>
      <Sheet.Backdrop onTap={onClose}></Sheet.Backdrop>
      <Sheet.Container>
        <Sheet.Header className="flex flex-col">
          <div className="h-[6px] w-[40px] bg-gray-300 mx-auto rounded-full mt-3"></div>
          <div className="flex flex-row mx-[24px] items-center border-b border-gray-300 py-3">
            <img
              src={vm.menu.photo ?? `${process.env.PUBLIC_URL}/images/no-image.png`}
              className="w-[40px] h-[40px] rounded-md object-cover"
            />
            <p className="px-2 text-gray-600">{t('recommendation.title')}</p>
            <button className="ml-auto text-orange-500" onClick={vm.onGoToTab}>
              {t('recommendation.goToCart')}
            </button>
          </div>
        </Sheet.Header>
        <Sheet.Content className="py-[20px]">
          <div className="w-screen flex flex-col px-[24px]">
            <div className="w-full flex flex-row items-center">
              <h2 className="w-full flex flex-wrap font-bold text-md">
                {t('recommendation.recommendedTitle', { name: vm.menu.name })}
              </h2>
            </div>
          </div>
          <div className="w-screen max-w-screen flex flex-row overflow-x-scroll pt-[20px] scrollbar-hide">
            {vm.recommendedIds.map((id) => (
              <RecommendedItem id={id} onClick={vm.onRecommendedClick(id)} key={id} />
            ))}
          </div>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}
