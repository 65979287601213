import { Grid, Modal } from '@sicpama/core-components';
import { COUNTRY_CODE, COUNTRY_VAT_PERCENT, STORE_ATTRIBUTE_NAME } from '../../constants';
import CompleteOrderListItem from 'pages/complete-order/components/CompleteOrderListItem';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '../../components/divider';
import { orderService } from '../../services';
import { useThemeStore } from '../../stores';
import numberLocaleFormat, {
  CURRENCY_TO_LOCALE_PRICE,
  roundNumberTo2DecimalPlaces,
} from '../../utils/numberLocaleFormat';
import { IReceiptInformation, ISessionHistoryDto } from '../../submodules/sicpama-shared';

interface Props {
  selectedOrder?: ISessionHistoryDto;
  onClose: VoidFunction;
}
const translationPrefix = 'pages.complete-order';

export const OrderHistoryDetail = ({ selectedOrder, onClose }: Props): ReactElement => {
  const { t } = useTranslation();
  const [receiptOrder, setReceiptOrder] = useState<IReceiptInformation | null>(null);
  const store = useThemeStore((state) => state.store);

  const serviceChargeAttribute = store?.attributes.find(
    (x) => x.name === STORE_ATTRIBUTE_NAME.SERVICE_CHARGE_PERCENT,
  );

  const serviceChargeAttributeValue = serviceChargeAttribute?.value ?? '10';

  const countryCode = store?.countryCode as COUNTRY_CODE;

  const gstValue = countryCode ? COUNTRY_VAT_PERCENT[countryCode] : 9;

  const serviceCharge = roundNumberTo2DecimalPlaces(
    (+(selectedOrder?.subTotal ?? 0) * +serviceChargeAttributeValue) / 100,
  );

  const gst = (+(selectedOrder?.grandTotal ?? 0) + +serviceCharge) * (gstValue / 100);

  useEffect(() => {
    (async () => {
      if (selectedOrder?.orderId) {
        setReceiptOrder(await orderService.getReceiptOrder(selectedOrder.orderId));
      } else {
        setReceiptOrder(null);
      }
    })();
  }, [selectedOrder?.orderId]);

  return (
    <Modal
      withCloseButton
      opened={!!selectedOrder}
      onClose={onClose}
      fullScreen={true}
      backgroundColor="bg-gray-200"
      title={
        <h4 className="font-bold text-2xl uppercase">#{selectedOrder?.orderId?.slice(0, 8)}</h4>
      }
      styles={{
        title: {
          width: '70%',
        },
        body: {
          height: '100%',
        },
      }}
      zIndex={101}
    >
      {receiptOrder && Object.keys(receiptOrder).length !== 0 && (
        <div>
          <Divider className="!h-[1px] !min-h-[1px] mb-2"></Divider>
          <Grid spans={[3, 3, 3, 3]}>
            <p className="pl-4 text-sm font-medium text-left text-gray-400">
              {t(`${translationPrefix}.dishName`)}
            </p>
            <p className="text-sm font-medium text-right text-gray-400">
              {t(`${translationPrefix}.unitPrice`)}
            </p>
            <p className="text-sm font-medium text-center text-gray-400">
              {t(`${translationPrefix}.quantity`)}
            </p>
            <p className="pr-4 text-sm font-medium text-right text-gray-400">
              {t(`${translationPrefix}.amount`)}
            </p>
          </Grid>

          <Divider className="!h-[1px] !min-h-[1px] mt-2"></Divider>

          <div className="flex flex-col mt-2 text-[13px] font-medium">
            {selectedOrder?.orderItems?.map((item) => (
              <CompleteOrderListItem item={item} key={item.id} />
            ))}
          </div>

          <Divider className="!h-[1px] !min-h-[1px] mb-2"></Divider>

          <div className="ml-4 mr-4 text-[15px] font-medium">
            <Grid spans={[6, 6]}>
              <p className="pb-2">{t(`pages.receipt.amountExcludedTax`)}</p>
              <p className="text-right">
                {numberLocaleFormat(
                  selectedOrder?.subTotal ?? 0,
                  CURRENCY_TO_LOCALE_PRICE[store?.currency],
                )}
              </p>
            </Grid>
            <div className="text-md">
              {selectedOrder?.coupons?.map((coupon, idx) => {
                const separator = idx !== 0 ? 'border-t border-gray-300' : '';
                const { discountedAmount } = coupon;
                return (
                  <div key={coupon.id} className={`flex flex-row ${separator} pb-2`}>
                    <p className="pr-2 truncate text-[#0000FF] flex-shrink min-w-0">
                      {coupon.name}
                    </p>
                    <p className="text-[#0000FF] flex-shrink-0 ml-auto whitespace-nowrap">
                      - &nbsp;
                      {numberLocaleFormat(
                        discountedAmount ?? 0,
                        CURRENCY_TO_LOCALE_PRICE[store?.currency],
                      )}
                    </p>
                  </div>
                );
              })}
            </div>

            {countryCode === COUNTRY_CODE.SG && (
              <div>
                <Grid spans={[6, 6]}>
                  <p className="">{`${t(
                    'common.serviceCharge',
                  )} (${serviceChargeAttributeValue}%)`}</p>
                  <p className="text-right">
                    {numberLocaleFormat(serviceCharge, CURRENCY_TO_LOCALE_PRICE[store?.currency])}
                  </p>
                </Grid>
                <Grid spans={[6, 6]}>
                  <p className="">{`${t('common.gst')} (${gstValue}%)`}</p>
                  <p className="text-right">
                    {numberLocaleFormat(gst, CURRENCY_TO_LOCALE_PRICE[store?.currency])}
                  </p>
                </Grid>
              </div>
            )}
            <Grid spans={[6, 6]}>
              <p className="pb-2">{t(`pages.receipt.amountIncludedTax`)}</p>
              <p className="text-right">
                {numberLocaleFormat(
                  selectedOrder?.grandTotal ?? 0,
                  CURRENCY_TO_LOCALE_PRICE[store?.currency],
                )}
              </p>
            </Grid>
          </div>

          <Divider className="!h-[1px] !min-h-[1px] mt-2 mb-2"></Divider>

          {receiptOrder.order.paidAt && (
            <div className="ml-4 mr-4 font-medium text-[#999999]">
              <Grid spans={[6, 6]}>
                <p className="pb-2">{t(`${translationPrefix}.paymentDate`)}</p>
                <p className="text-right">{receiptOrder.order.paidAt}</p>
              </Grid>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};
