import type { ReactElement } from 'react';

interface Props {
  className?: string;
}
const Divider = ({ className }: Props): ReactElement => {
  return <div className={`w-full h-[7px] bg-inactive min-h-[7px] ${className ?? ''}`} />;
};

export default Divider;
