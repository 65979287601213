import styled from '@emotion/styled';
import {
  Accordion,
  Carousel,
  Divider,
  Grid,
  Icon,
  Modal,
  Typography,
} from '@sicpama/core-components';
import { COUNTRY_CODE, COUNTRY_VAT_PERCENT, STORE_ATTRIBUTE_NAME } from '../../constants';
import CompleteOrderListItem from 'pages/complete-order/components/CompleteOrderListItem';
import { OrderHistoryDetail } from 'pages/menu/OrderHistoryDetail';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOrderStore, useThemeStore } from 'stores';

import { StoreBankAccount } from 'components/store-bank-account';
import { orderService } from '../../services';
import numberLocaleFormat, {
  CURRENCY_TO_LOCALE_PRICE,
  roundNumberTo2DecimalPlaces,
} from '../../utils/numberLocaleFormat';

import './OrderHistoryCarousel.scss';
import { ISessionHistoryDto, OrderStatus, PaymentStatus } from '../../submodules/sicpama-shared';

const renderMenuItemHeaders = (t: any): ReactElement => {
  return (
    <Grid spans={[3, 3, 3, 3]}>
      <p className="pl-4 text-sm font-medium text-left text-gray-400">
        {t('pages.complete-order.dishName')}
      </p>
      <p className="text-sm font-medium text-right text-gray-400">
        {t('pages.complete-order.unitPrice')}
      </p>
      <p className="text-sm font-medium text-center text-gray-400">
        {t('pages.complete-order.quantity')}
      </p>
      <p className="pr-4 text-sm font-medium text-right text-gray-400">
        {t('pages.complete-order.amount')}
      </p>
    </Grid>
  );
};

const OrderHistoryCarousel = (): ReactElement | null => {
  const [historySessions, setHistorySessions] = useState<ISessionHistoryDto[]>([]);
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState<string | null>(null);
  const selectedOrder = historySessions.find((s) => s.orderId === selectedOrderId);

  const {
    theme: { color: themeColor },
    store,
  } = useThemeStore();
  const { tableOrders, myOrder } = useOrderStore();

  const serviceChargeAttribute = store?.attributes.find(
    (x) => x.name === STORE_ATTRIBUTE_NAME.SERVICE_CHARGE_PERCENT,
  );

  const serviceChargeAttributeValue = serviceChargeAttribute?.value ?? '10';

  const countryCode = store?.countryCode as COUNTRY_CODE;

  const gstValue = countryCode ? COUNTRY_VAT_PERCENT[countryCode] : 9;

  const totalUnPaidSubTotal = useMemo(() => {
    return historySessions
      .filter((s) => s.paymentStatus !== PaymentStatus.SUCCESS)
      .reduce((acc, s) => acc + (s.subTotal ?? 0), 0);
  }, [historySessions]);

  const totalUnPaidGrandTotal = useMemo(() => {
    return historySessions
      .filter((s) => s.paymentStatus !== PaymentStatus.SUCCESS)
      .reduce((acc, s) => acc + (s.grandTotal ?? 0), 0);
  }, [historySessions]);

  const serviceCharge = roundNumberTo2DecimalPlaces(
    (totalUnPaidSubTotal * +serviceChargeAttributeValue) / 100,
  );

  const gst = (+totalUnPaidSubTotal + +serviceCharge) * (gstValue / 100);

  const coupons = useMemo(() => {
    return historySessions
      .filter((s) => s.paymentStatus !== PaymentStatus.SUCCESS)
      .map((x) => x.coupons)
      .flat();
  }, [historySessions]);

  useEffect(() => {
    (async () => {
      setHistorySessions(
        (await orderService.getSessionHistories()).filter((s) =>
          [OrderStatus.PAID, OrderStatus.PRINTED].includes(s.status),
        ),
      );
    })();
  }, [myOrder]);

  if (!historySessions?.length) return null;

  return (
    <div className="w-full p-4 bg-gray-200">
      <Carousel
        items={[
          <div key="initial-key" className="flex flex-col justify-between w-[100px]">
            <h4 className="font-bold text-sm">{t('common.myOrderHistory')}</h4>
            <h6
              className="text-sm text-theme"
              onClick={() => {
                setShowModal(true);
              }}
            >
              {t('common.viewAll')}
            </h6>
          </div>,
          ...historySessions.map((session, index) => (
            <div
              key={session.orderId ?? index}
              onClick={() => {
                setSelectedOrderId(session.orderId ?? null);
              }}
              className="bg-white p-2 rounded-md flex items-center justify-between w-[130px]"
            >
              <h4 className="font-bold text-sm text-theme uppercase">
                #{session.orderId?.slice(0, 8)}
              </h4>
              <Icon name="RightLarge" size={20} type="filled" />
            </div>
          )),
        ]}
        slideSize="60px"
        slideGap="sm"
        loop={false}
        align="start"
        withControls={false}
      />
      <Modal
        className="order-history-container"
        withCloseButton
        opened={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        fullScreen={true}
        backgroundColor="bg-gray-200"
        title={<h4 className="font-bold text-2xl">{t('common.myOrderHistory')}</h4>}
        styles={{
          body: {
            height: '100%',
          },
        }}
        zIndex={100}
      >
        <div className="pb-4">
          <StyledAccordion
            styles={{
              item: {
                overflow: 'hidden',
                border: '2px solid var(--store-theme-color)!important',
                borderRadius: '20px',
                marginTop: 8,
              },
            }}
            className="mb-4"
            openedMultiple={true}
            openedDefault={historySessions
              .map((s, index) => s.orderId ?? `${index}`)
              .filter((s) => !!s)}
            items={historySessions
              .filter((x) => x.paymentStatus === PaymentStatus.SUCCESS)
              .map((session, index) => {
                const order = tableOrders.find((o) => o.customerId === session.customerId);
                return {
                  title: (
                    <div
                      key={session.orderId ?? index}
                      className="border-theme bg-white p-2 rounded-md flex items-center justify-between w-full gap-8"
                    >
                      <h4 className="text-base truncate uppercase">
                        {order?.customer.fullName === 'Guest' && order?.isMyself
                          ? t('common.me')
                          : order?.customer.fullName}
                      </h4>
                      <img src="/images/paid-sign.jpg" alt="Paid" width={60} height={60} />
                      <div className="text-right uppercase font-bold">
                        #{session.orderId?.slice(0, 8)}
                      </div>
                    </div>
                  ),
                  value: session.orderId ?? `${index}`,
                  content: (
                    <div>
                      {session.orderItems.map((item) => {
                        return (
                          <div key={item.id}>
                            {renderMenuItemHeaders(t)}

                            <Divider className="!h-[1px] !min-h-[1px] mt-2"></Divider>

                            <div className="flex flex-col mt-2 text-[13px] font-medium">
                              <CompleteOrderListItem item={item} key={item.id} />
                            </div>

                            <Divider className="!h-[1px] !min-h-[1px] mb-2"></Divider>

                            <div className="ml-4 mr-4 text-[15px] font-medium">
                              <Grid spans={[6, 6]}>
                                <p className="pb-2">{t('common.subTotal')}</p>
                                <p className="text-right">
                                  {numberLocaleFormat(
                                    session?.subTotal ?? 0,
                                    CURRENCY_TO_LOCALE_PRICE[store?.currency],
                                  )}
                                </p>
                              </Grid>
                              <Grid spans={[6, 6]}>
                                <p className="pb-2">{t('common.grandTotal')}</p>
                                <p className="text-right">
                                  {numberLocaleFormat(
                                    session?.grandTotal ?? 0,
                                    CURRENCY_TO_LOCALE_PRICE[store?.currency],
                                  )}
                                </p>
                              </Grid>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ),
                };
              })}
          />
          {/* PAY AT COUNTER LIST */}
          <div className="border-2 rounded-xl border-indigo-500 py-4 mb-4">
            <Typography className="pl-4 text-xl font-bold text-indigo-700 pb-4">
              {t('common.pay_in_store')}
            </Typography>
            {renderMenuItemHeaders(t)}
            <Divider className="!h-[1px] !min-h-[1px] mb-2"></Divider>
            {historySessions
              .filter((x) => x.paymentStatus !== PaymentStatus.SUCCESS)
              .map((session) => {
                return session.orderItems.map((item) => {
                  return (
                    <div key={item.id}>
                      <div className="flex flex-col mt-2 text-[13px] font-medium">
                        <CompleteOrderListItem item={item} key={item.id} />
                      </div>
                      <Divider className="!h-[1px] !min-h-[1px] mb-2"></Divider>
                    </div>
                  );
                });
              })}
            <div className="ml-4 mr-4 text-[15px] font-medium">
              <Grid spans={[6, 6]}>
                <p className="pb-2">{t('common.subTotal')}</p>
                <p className="text-right">
                  {numberLocaleFormat(
                    totalUnPaidSubTotal,
                    CURRENCY_TO_LOCALE_PRICE[store?.currency],
                  )}
                </p>
              </Grid>
              <div className="text-md">
                {coupons.map((coupon, idx) => {
                  const separator = idx !== 0 ? 'border-t border-gray-300' : '';
                  const { discountedAmount } = coupon;
                  return (
                    <div key={coupon.id} className={`flex flex-row ${separator} pb-2`}>
                      <p className="pr-2 truncate text-[#0000FF] flex-shrink min-w-0">
                        {coupon.name}
                      </p>
                      <p className="text-[#0000FF] flex-shrink-0 ml-auto whitespace-nowrap">
                        - &nbsp;
                        {numberLocaleFormat(
                          discountedAmount ?? 0,
                          CURRENCY_TO_LOCALE_PRICE[store?.currency],
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
              {countryCode === COUNTRY_CODE.SG && (
                <div>
                  <Grid spans={[6, 6]}>
                    <p className="">{`${t(
                      'common.serviceCharge',
                    )} (${serviceChargeAttributeValue}%)`}</p>
                    <p className="text-right">
                      {numberLocaleFormat(serviceCharge, CURRENCY_TO_LOCALE_PRICE[store?.currency])}
                    </p>
                  </Grid>
                  <Grid spans={[6, 6]}>
                    <p className="">{`${t('common.gst')} (${gstValue}%)`}</p>
                    <p className="text-right">
                      {numberLocaleFormat(gst, CURRENCY_TO_LOCALE_PRICE[store?.currency])}
                    </p>
                  </Grid>
                </div>
              )}
              <Grid spans={[6, 6]}>
                <p className="pb-2">{t('common.grandTotal')}</p>
                <p className="text-right">
                  {numberLocaleFormat(
                    totalUnPaidGrandTotal,
                    CURRENCY_TO_LOCALE_PRICE[store?.currency],
                  )}
                </p>
              </Grid>
            </div>
          </div>

          {store?.bankAccountInfo && (
            <StoreBankAccount store={store} themeColor={themeColor} marginX={0} />
          )}
        </div>
      </Modal>
      <OrderHistoryDetail
        selectedOrder={selectedOrder}
        onClose={() => {
          setSelectedOrderId(null);
        }}
      />
    </div>
  );
};

export default OrderHistoryCarousel;

const StyledAccordion = styled(Accordion)`
  .mantine-Accordion-item {
    border-bottom: none;
  }
`;
