import { Typography } from '@sicpama/core-components';
import { useOrderStore } from 'stores';
import { LanguageService } from 'services';
interface AvailableCouponModalItemProps {
  readonly id: string;
  readonly separator: boolean;
}

function AvailableCouponModalItem({ id, separator }: AvailableCouponModalItemProps): JSX.Element {
  const { couponIdToCoupon } = useOrderStore();
  const customerCoupon = couponIdToCoupon[id];
  const advert = customerCoupon.advert?.location.USABLE_COUPON_LIST;
  const BORDER_SEPARATOR = 'border-b border-gray-300';
  if (advert !== undefined) {
    const selectedAdvert = advert[LanguageService.getLanguage()] ?? advert.en;
    return (
      <div
        dangerouslySetInnerHTML={{ __html: selectedAdvert }}
        className={`pt-6 pb-2 ${separator && BORDER_SEPARATOR}`}
      />
    );
  }
  return (
    <div className={`pt-6 pb-2 flex flex-col items-center ${separator && BORDER_SEPARATOR}`}>
      <img
        src={`${process.env.PUBLIC_URL}/images/free-coffee-coupon.png`}
        alt=""
        className="flex w-32 h-32 mb-2"
      />
      <Typography weight="bold" align="center">
        {customerCoupon.name}
      </Typography>
    </div>
  );
}

export default AvailableCouponModalItem;
