import { logger } from 'configs';
import BaseHttpService from './base-http.service';
import { ConsentInfo } from 'stores';
import {
  ICurrentTableSessionDto,
  IPaidOrderResDto,
  IReceiptInformation,
  OrderStatus,
  PaymentOption,
  PaymentStatus,
} from '../submodules/sicpama-shared';
import {
  AddOrderItemsToOrderResponseDto,
  SpinTheWheelResDto,
  UpdateOrderItemDto,
} from '../types/order-items';
import { ISessionHistoriesDto, ISessionHistoryDto } from '../submodules/sicpama-shared/dto/history';

const PATH = 'orders';

class OrderService extends BaseHttpService {
  async getAllOrderItemsInTheSameTable(statuses?: OrderStatus[]): Promise<ICurrentTableSessionDto> {
    const body = await this.get<ICurrentTableSessionDto>(`${PATH}/items/tables`, {
      params: {
        statuses: statuses != null || [
          OrderStatus.DRAFT,
          OrderStatus.WAITING,
          OrderStatus.PAYING,
          OrderStatus.PAID,
          OrderStatus.PRINTED,
        ],
      },
    });
    return {
      data: body.data ?? [],
      coupons: body.coupons ?? [],
      billInformation: body.billInformation,
    };
  }

  async getSessionHistories(statuses?: OrderStatus[]): Promise<ISessionHistoryDto[]> {
    const body = await this.get<ISessionHistoriesDto>(`${PATH}/sessions/histories`, {
      params: {
        statuses: statuses != null || [
          OrderStatus.DRAFT,
          OrderStatus.WAITING,
          OrderStatus.PAYING,
          OrderStatus.PAID,
          OrderStatus.PRINTED,
        ],
      },
    });
    return body.data || [];
  }

  async addMenuItemToOrder(data: UpdateOrderItemDto): Promise<void> {
    await this.post<AddOrderItemsToOrderResponseDto>(`${PATH}/items`, data);
  }

  async updateMenuItemToOrder(id: string, data: UpdateOrderItemDto): Promise<void> {
    await this.put(`${PATH}/items/${id}`, data);
  }

  async deleteMenuItemFromOrder(orderItemId: string): Promise<void> {
    await this.delete(`${PATH}/items/${orderItemId}`);
  }

  async setPaymentOptionToOrder(orderId: string, paymentOption: PaymentOption): Promise<void> {
    await this.post(`${PATH}/${orderId}/set-payment-option`, { paymentOption });
  }

  async setOrderStatusToDraft(): Promise<void> {
    logger.info('setOrderStatusToDraft_href: ', {
      hrefUrl: window.location.href,
      pathname: window.location.pathname,
    });
    if (window.location.pathname !== '/payment') return;
    await this.post(`${PATH}/set-status-to-draft`);
  }

  async setOrderStatus(
    orderId: string,
    status: OrderStatus,
    consentInfo?: ConsentInfo,
    paymentStatus?: PaymentStatus,
  ): Promise<void> {
    await this.post(`${PATH}/${orderId}/set-status`, {
      status,
      paymentStatus,
      ...consentInfo,
    });
  }

  async checkOutOrder(): Promise<void> {
    await this.post(`${PATH}/checkout`);
  }

  async reAddOrder(): Promise<void> {
    await this.post<AddOrderItemsToOrderResponseDto>(`${PATH}/re-add`);
  }

  async getLatestPaid(): Promise<IPaidOrderResDto | null> {
    return await this.get<IPaidOrderResDto>(`${PATH}/latest-paid`);
  }

  async spinTheWheel(): Promise<SpinTheWheelResDto | null> {
    try {
      return await this.post<SpinTheWheelResDto>(`${PATH}/spin-the-wheel`);
    } catch (e) {
      return null;
    }
  }

  async clearSpinTheWheel(): Promise<SpinTheWheelResDto> {
    return await this.post<SpinTheWheelResDto>(`${PATH}/clear-spin-the-wheel`);
  }

  async getReceiptOrder(orderId: string): Promise<IReceiptInformation> {
    return await this.get<IReceiptInformation>(`${PATH}/${orderId}/receipts`);
  }
}

export const orderService = new OrderService();
