import { CenterLoader } from 'components/loader';
import InputNumber from 'components/systems/InputNumber';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';
import MultiOption from './components/MultiOption';
import RecommendationSheet from './components/RecommendationSheet';
import SingleOption from './components/SingleOption';
import MultiLinesText from 'components/multi-lines-text';
import useMenuDetail from './useMenuDetail';

export default function MenuDetail(): JSX.Element {
  const { t } = useTranslation();
  const vm = useMenuDetail();

  useEffect(() => {
    if (!vm.successLoading) {
      vm.onGoBack();
    }
  }, [vm.menu, vm.onGoBack]);

  if (vm.loading || vm.menu === undefined) {
    return <CenterLoader />;
  }

  const submitButtonStyle =
    vm.menu.isAvailable && !vm.buttonLoading && vm.menuOptionValidated
      ? 'bg-[#191749]'
      : 'bg-gray-200';

  return (
    <>
      <div className="flex flex-col min-h-screen mb-20 overflow-x-hidden overflow-y-auto bg-gray-100 ">
        <div className="w-screen h-[220px] relative">
          <img
            className="w-full h-full object-cover border-b border-[#C2D1D9]"
            src={vm.menu.photo ?? `${process.env.PUBLIC_URL}/images/no-image.png`}
            id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.MENU_BANNER_IMAGE}
          />
          <img
            className="absolute top-0 left-0 w-10 h-10 p-2 mt-2 ml-2 bg-gray-100 rounded-full"
            src={`${process.env.PUBLIC_URL}/icons/close-icon.svg`}
            onClick={vm.onGoBack}
            id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.GO_BACK_TO_MENU_PAGE}
          />
        </div>
        <div className="flex flex-col w-full px-3 py-4 mb-4 bg-white">
          <h1 className="pb-2 text-lg font-bold">
            <MultiLinesText text={vm.menu.name} />
          </h1>
          <p className="text-xs text-gray-400 max-h-[66px] overflow-y-hidden mb-4 border-b border-gray-200 pb-4">
            {vm.menu.description}
          </p>
          <div className="flex flex-row justify-between">
            <p>{t('pages.menu.price')}</p>
            <p>{numberLocaleFormat(vm.getPrice(), CURRENCY_TO_LOCALE_PRICE[vm.menu.currency])}</p>
          </div>
          <div className="flex flex-row justify-between mt-6">
            <p>{t('pages.menu.quantity')}</p>
            <InputNumber minLimit={1} onChange={vm.onChangeQuantity} value={vm.quantity} />
          </div>
        </div>
        {vm.menu.menuOptions.map((menuOption) =>
          vm.isMultipleChoice(menuOption) ? (
            <MultiOption
              disabled={vm.isMaxChoicesReached(menuOption)}
              key={menuOption.id}
              menuOption={menuOption}
              selectedChoices={vm.multiOptions[menuOption.id]}
              currency={vm.menu.currency}
              onChange={vm.onMultiMenuOptionChange}
            />
          ) : (
            <SingleOption
              selectedChoiceId={vm.singleOptions[menuOption.id]}
              menuOption={menuOption}
              key={menuOption.id}
              onOptionChange={vm.onSingleMenuOptionChange}
              currency={vm.menu.currency}
            />
          ),
        )}
        <RecommendationSheet
          open={vm.showRecommendationMenu}
          onClose={vm.onHideRecommendationMenu}
        />
      </div>
      <div
        className={`fixed w-full bottom-0 ${submitButtonStyle} h-20 flex items-center justify-center`}
        onClick={vm.onSave}
        id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.ADD_MENU_TO_CART_BTN}
      >
        {vm.buttonLoading && <CenterLoader />}
        <p className="font-bold text-white ">
          {t('pages.menu.addToCart', {
            price: numberLocaleFormat(
              +vm.getFinalPrice(),
              CURRENCY_TO_LOCALE_PRICE[vm.menu.currency],
            ),
          })}
        </p>
      </div>
    </>
  );
}
