import { useCallback } from 'react';

interface CheckboxProps {
  readonly text: string;
  readonly price?: string;
  readonly selected: boolean;
  readonly onChange: () => void;
  readonly disabled: boolean;
}

export default function Checkbox({
  text,
  selected,
  onChange,
  price,
  disabled,
}: CheckboxProps): JSX.Element {
  const checkStyle = selected
    ? 'w-6 h-6  border-2 border-orange-500 bg-orange-500'
    : `w-6 h-6 border-2 border-gray-400 ${disabled && 'bg-gray-400'}`;

  const handleChange = useCallback(() => {
    if (!disabled || selected) {
      onChange();
    }
  }, [onChange, disabled]);

  return (
    <div className="w-full flex flex-row py-2 justify-between" onClick={handleChange}>
      <div className="w-full flex flex-row">
        <div className={`${checkStyle} mr-2`}></div>
        <p className="text-gray-500">{text}</p>
      </div>
      {price && <p>{price}</p>}
    </div>
  );
}
