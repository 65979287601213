import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import { useCallback } from 'react';

interface InputNumberProps {
  readonly value: number;
  readonly minLimit?: number;
  readonly onChange: (value: number) => void;
}

export default function InputNumber({ value, minLimit, onChange }: InputNumberProps): JSX.Element {
  const onMore = useCallback(() => {
    onChange(value + 1);
  }, [value]);
  const onLess = useCallback(() => {
    if (minLimit !== undefined && value <= minLimit) {
      return;
    }
    onChange(value - 1);
  }, [value, minLimit]);

  return (
    <div className="flex flex-row border border-orange-500 py-1 px-4 rounded-full">
      <div
        onClick={onLess}
        className="mr-5"
        id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.MINUS_QUANTITY_BTN}
      >
        -
      </div>
      <p id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.QUANTITY_NUMBER}>{value}</p>
      <div
        onClick={onMore}
        className="ml-5"
        id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.PLUS_QUANTITY_BTN}
      >
        +
      </div>
    </div>
  );
}
