import { ANALYTICS_CSS_ID } from '../../../../constants';
import { menuSelectors, useMenuStore } from 'stores';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';

interface MenuItemCarouselProps {
  id: number;
  onClick: () => void;
}

export default function MenuItemCarousel({ id, onClick }: MenuItemCarouselProps): JSX.Element {
  const menu = useMenuStore(menuSelectors.getBaseMenuById(id));
  return (
    <div
      className="flex flex-col w-[160px] text-sm gap-1"
      onClick={onClick}
      id={`${ANALYTICS_CSS_ID(menu?.id).EVENT_MENU}`}
    >
      <img
        src={menu?.photo ?? 'images/no-image.png'}
        className="w-[160px] h-[160px] object-cover rounded-lg"
        style={{
          filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.4))',
        }}
      />
      <div className="mt-1 font-bold text-[#222222] truncate ...">{menu?.name}</div>
      <div className="flex gap-2">
        <span className={`${menu?.isTodayDiscounted ? 'line-through' : ''} text-[#868686]`}>
          {numberLocaleFormat(+menu?.price, CURRENCY_TO_LOCALE_PRICE[menu?.currency])}
        </span>
        {menu?.isTodayDiscounted && menu?.discountPrice && (
          <span className="text-theme font-bold">
            {numberLocaleFormat(+menu?.discountPrice, CURRENCY_TO_LOCALE_PRICE[menu?.currency])}
          </span>
        )}
      </div>
    </div>
  );
}
