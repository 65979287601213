import { Icon, Typography, Badge } from '@sicpama/core-components';
import { notifications } from '@sicpama/core-components/lib/notifications';
import { useTranslation } from 'react-i18next';

import { StoreResDto } from 'types';

interface StoreBankAccountProps {
  store?: StoreResDto;
  themeColor?: string;
  marginX?: number;
}

const translationPrefix = 'pages.complete-order';

export const StoreBankAccount = ({
  store,
  themeColor,
  marginX = 4,
}: StoreBankAccountProps): JSX.Element => {
  const { t } = useTranslation();

  const copyBankAccountToClipboard = (text: string): void => {
    navigator.clipboard.writeText(text);
    notifications.show({
      withCloseButton: true,
      autoClose: 2000,
      message: 'Copied',
      icon: <Icon name="TickCircle" color="white" />,
      color: 'green',
    });
  };

  return (
    <div className={`border-2 rounded-xl border-[#C7C7C7] mx-${marginX} mb-4`}>
      <div className="flex flex-wrap px-4 py-4 items-center justify-between">
        <Typography className="text-base font-bold mr-2">
          {t(`${translationPrefix}.bankTransfer.title`)}
        </Typography>
        <div className="flex flex-1 min-w-0 items-center">
          <Badge
            className="mr-2 flex-1 min-w-0 text-sm"
            title={`${store?.bankAccountInfo?.bankName}${store?.bankAccountInfo?.accountNumber}`}
            borderColor={themeColor}
            backgroundColor={themeColor}
            titleColor="white"
          />
          <Icon
            name="CopyBg"
            type="outline"
            size={36}
            onClick={() => {
              copyBankAccountToClipboard(
                `${store?.bankAccountInfo?.bankName}${store?.bankAccountInfo?.accountNumber}`,
              );
            }}
          />
        </div>
      </div>
      <Typography className="px-4 text-sm text-[#5B5B5B] pb-4">
        {t(`${translationPrefix}.bankTransfer.description`, {
          storeName: store?.name,
        })}
      </Typography>
    </div>
  );
};
