import axios from 'axios';
import {
  AirwallexCreatePaymentIntentRequestDto,
  AirwallexCreatePaymentIntentResponseDto,
} from 'types';
import type { KICCWebPaymentRequestPayload } from 'types';
import { PAYMENT_GATEWAY_APIS } from '../constants/payment.constant';
import BaseHttpService from './base-http.service';
import { CreateCheckoutSessionResponse } from '@adyen/api-library/lib/src/typings/checkout/createCheckoutSessionResponse';
import { roundNumberTo2DecimalPlaces } from '../utils/numberLocaleFormat';
import { decrypt } from '../utils/payment';
import { PaymentGatewayType, PaymentMethodType } from '../submodules/sicpama-shared';

const KICC_THIRD_PARTY_PATH =
  PAYMENT_GATEWAY_APIS[PaymentGatewayType.KICC][PaymentMethodType.WEB_PAY];

const PAYMENT_PATH = 'payments';

class KICCWebPaymentService {
  async getWebPaymentPopupURL(payload: KICCWebPaymentRequestPayload): Promise<string> {
    const response = await axios.post(KICC_THIRD_PARTY_PATH, payload);
    return response.data.authPageUrl;
  }
}

export const kiccPaymentService = new KICCWebPaymentService();

class AirwallexPaymentService extends BaseHttpService {
  async createAccessToken(): Promise<string> {
    const response = await this.post<string>(`${PAYMENT_PATH}/login`);
    return response;
  }

  async createPaymentIntent(
    token: string,
    payload: AirwallexCreatePaymentIntentRequestDto,
  ): Promise<AirwallexCreatePaymentIntentResponseDto> {
    const response = await this.post<AirwallexCreatePaymentIntentResponseDto>(
      `${PAYMENT_PATH}/intents`,
      {
        token,
        payload,
      },
    );
    return response;
  }
}

export const airwallexPaymentService = new AirwallexPaymentService();

class OnePGService extends BaseHttpService {
  private readonly ONEPG_PAYMENT_URL = `${process.env.REACT_APP_ONEPG_PAYMENT_URL}`;

  async paymentPopup(payload: FormData): Promise<void> {
    await axios.post(`${this.ONEPG_PAYMENT_URL}/pay/easyCreditCard`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export const onePGService = new OnePGService();

class AdyenPGService extends BaseHttpService {
  async createPaymentSession(input: { amount: number }): Promise<CreateCheckoutSessionResponse> {
    const response = await this.post<CreateCheckoutSessionResponse>(
      `${PAYMENT_PATH}/sessions/${PaymentGatewayType.ADYEN}`,
      { amount: roundNumberTo2DecimalPlaces(input.amount) },
    );

    return response;
  }
}

export const adyenPGService = new AdyenPGService();

class StripeService extends BaseHttpService {
  async getPaymentConfig(): Promise<{ publicKey: string; accountId: string }> {
    const response = await this.get<{ publicKey: string; accountId: string }>(
      `${PAYMENT_PATH}/payment-gateways/${PaymentGatewayType.STRIPE}/config`,
    );

    return {
      publicKey: decrypt({
        encryptedText: response.publicKey,
        secretKey: process.env.REACT_APP_SECRET_KEY ?? '',
        iv: process.env.REACT_APP_SECRET_IV ?? '',
      }),
      accountId: decrypt({
        encryptedText: response.accountId,
        secretKey: process.env.REACT_APP_SECRET_KEY ?? '',
        iv: process.env.REACT_APP_SECRET_IV ?? '',
      }),
    };
  }

  async createPaymentIntent(input: { amount: number }): Promise<{ clientSecret: string }> {
    const response = await this.post<{ clientSecret: string }>(
      `${PAYMENT_PATH}/payment-gateways/${PaymentGatewayType.STRIPE}/intents`,
      { amount: roundNumberTo2DecimalPlaces(input.amount) },
    );

    return response;
  }
}

export const stripeService = new StripeService();
