import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { menuSelectors, OrderSelectors, useAddMenu, useMenuStore, useOrderStore } from 'stores';
import { getMenuRecommendedIds } from './components/RecommendationSystem';
import { IBaseMenuOptionResDto, IMenuDetailsResDto } from '../../submodules/sicpama-shared';

interface UseMenuDetailProps {
  readonly loading: boolean;
  readonly successLoading: boolean;
  readonly menu: IMenuDetailsResDto;
  readonly singleOptions: Record<number, number>;
  readonly multiOptions: Record<number, number[]>;
  readonly quantity: number;
  readonly buttonLoading: boolean;
  readonly showRecommendationMenu: boolean;
  readonly menuOptionValidated: boolean;
  readonly onChangeQuantity: (v: number) => void;
  readonly onHideRecommendationMenu: () => void;
  readonly getPrice: () => number;
  readonly getFinalPrice: () => number;
  readonly onGoBack: () => void;
  readonly onSingleMenuOptionChange: (optionId: number, choiceId: number) => void;
  readonly onMultiMenuOptionChange: (optionId: number, choiceId: number) => void;
  readonly onSave: () => Promise<void>;
  readonly isMultipleChoice: (menuOption: IBaseMenuOptionResDto) => boolean;
  readonly isMaxChoicesReached: (menuOption: IBaseMenuOptionResDto) => boolean;
}

export default function useMenuDetail(): UseMenuDetailProps {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [successLoading, setSuccessLoading] = useState(true);
  const [singleOptions, setSingleOptions] = useState<Record<number | string, number>>({});
  const [showRecommendationMenu, setShowRecommendationMenu] = useState(false);
  const [multiOptions, setMultiOptions] = useState<Record<number, number[]>>({});
  const [quantity, setQuantity] = useState(1);

  const navigate = useNavigate();
  const menu = useMenuStore(menuSelectors.getMenuById(id ?? -1));
  const addMenu = useAddMenu((state) => state.addMenu);
  const getMenuDetail = useMenuStore((state) => state.getMenuDetail);
  const currentOrderItems = useOrderStore(OrderSelectors.getCurrentOrderItems);
  const recommendationSystemList = useMenuStore(
    menuSelectors.getRecommendationSystemList(id ?? -1),
  );
  const menuIds = useMenuStore(menuSelectors.getBaseMenuIds);
  const recommendedMenu = useMemo(
    () => getMenuRecommendedIds(currentOrderItems, recommendationSystemList, menuIds),
    [currentOrderItems, recommendationSystemList],
  );

  const onHideRecommendationMenu = useCallback(() => {
    setShowRecommendationMenu(false);
  }, []);

  const onChangeQuantity = useCallback((v: number) => {
    setQuantity(v);
  }, []);

  const getPrice = useCallback(
    () => (menu.isTodayDiscounted ? menu.discountPrice ?? +menu.price : +menu.price),
    [menu],
  );

  const getSingleOptionPrice = useCallback(() => {
    const singleSelectedChoiceId = Object.keys(singleOptions)
      .map((e) => parseInt(e))
      .map((id) => singleOptions[id]);
    return menu?.menuOptions
      .map((menuOption) => menuOption.choices)
      .flat()
      .filter((choice) => singleSelectedChoiceId.includes(choice.id))
      .map((choice) => +choice.price)
      .reduce((a, b) => a + b, 0);
  }, [singleOptions]);

  const getMultiOptionPrice = useCallback(() => {
    const multiSelectedChoiceId = Object.keys(multiOptions)
      .map((e) => parseInt(e))
      .map((id) => multiOptions[id])
      .flat();
    return menu?.menuOptions
      .map((menuOption) => menuOption.choices)
      .flat()
      .filter((choice) => multiSelectedChoiceId.includes(choice.id))
      .map((choice) => +choice.price)
      .reduce((a, b) => a + b, 0);
  }, [multiOptions]);

  const getFinalPrice = useCallback(() => {
    const basePrice = getPrice();
    const singleOptionPrice = getSingleOptionPrice() ?? 0;
    const multiOptionPrice = getMultiOptionPrice() ?? 0;
    return (basePrice + singleOptionPrice + multiOptionPrice) * quantity;
  }, [getPrice, getMultiOptionPrice, getSingleOptionPrice, quantity]);

  const onSave = useCallback(async () => {
    if (!menu.isAvailable || buttonLoading || loading || !menuOptionValidated) {
      return;
    }
    setButtonLoading(true);
    await addMenu(menu.id, {
      detail: {
        options: {
          ...singleOptions,
          ...multiOptions,
        },
        quantity,
      },
    });

    setButtonLoading(false);
    if (recommendedMenu.length > 0) {
      setShowRecommendationMenu(true);
    } else {
      navigate('/menus', { state: { target: 'menus-list' } });
    }
  }, [singleOptions, multiOptions, recommendedMenu, quantity]);

  const menuOptionValidated = useMemo(() => {
    return (
      menu !== undefined &&
      menu.menuOptions
        .filter((menuOpt) => menuOpt.maxChoices > 1 && menuOpt.minChoices > 0)
        .map((menuOpt) => menuOpt.minChoices <= multiOptions[menuOpt.id]?.length)
        .filter((bool) => !bool).length <= 0
    );
  }, [menu, multiOptions]);

  const isMultipleChoice = useCallback(
    (menuOption: IBaseMenuOptionResDto) => menuOption.maxChoices > 1,
    [],
  );
  const isMaxChoicesReached = useCallback(
    (menuOption: IBaseMenuOptionResDto) =>
      menuOption.maxChoices !== undefined &&
      multiOptions[menuOption.id] !== undefined &&
      multiOptions[menuOption.id].length >= menuOption.maxChoices,
    [multiOptions],
  );

  const onSingleMenuOptionChange = useCallback((optionId: number, choiceId: number) => {
    setSingleOptions((e) => ({ ...e, [optionId]: choiceId }));
  }, []);

  const onMultiMenuOptionChange = useCallback(
    (optionId: number, choiceId: number) => {
      const option: number[] | undefined = multiOptions[optionId];
      if (option === undefined) {
        throw new Error('multi option is undefined');
      }
      if (option.includes(choiceId)) {
        setMultiOptions((e) => ({
          ...e,
          [optionId]: e[optionId].filter((id) => id !== choiceId),
        }));
      } else {
        setMultiOptions((e) => ({
          ...e,
          [optionId]: e[optionId].concat(choiceId),
        }));
      }
    },
    [multiOptions],
  );

  const handleDefaultSingleOption = useCallback(
    (menuOptions: IBaseMenuOptionResDto[]) => {
      const defaultSingleOptions: Record<number, number> = {};
      menuOptions
        .filter((menuOpt) => !isMultipleChoice(menuOpt))
        .forEach((menuOpt) => {
          defaultSingleOptions[menuOpt.id] = menuOpt.choices[0].id;
        });
      setSingleOptions(defaultSingleOptions);
    },
    [isMultipleChoice],
  );

  const handleDefaultMultiOption = useCallback((menuOptions: IBaseMenuOptionResDto[]) => {
    const defaultMultiOption: Record<number, number[]> = {};
    menuOptions
      .filter((menuOpt) => isMultipleChoice(menuOpt))
      .forEach((menuOpt) => {
        defaultMultiOption[menuOpt.id] = [];
      });
    setMultiOptions(defaultMultiOption);
  }, []);

  const loadMenuDetail = useCallback(async () => {
    if (id === undefined || Number.isNaN(id)) {
      return;
    }
    setLoading(true);
    const res = await getMenuDetail(parseInt(id));
    if (res === null) {
      setSuccessLoading(false);
      setLoading(false);
      return;
    }
    handleDefaultMultiOption(res.menuOptions);
    setLoading(false);
  }, [id]);

  const onGoBack = useCallback(() => {
    navigate('/menus', { state: { target: 'menus-list' } });
  }, []);

  useEffect(() => {
    loadMenuDetail();
  }, [loadMenuDetail]);

  return {
    loading,
    successLoading,
    menu,
    quantity,
    singleOptions,
    getPrice,
    getFinalPrice,
    showRecommendationMenu,
    onHideRecommendationMenu,
    onSave,
    onGoBack,
    onSingleMenuOptionChange,
    onChangeQuantity,
    onMultiMenuOptionChange,
    isMultipleChoice,
    isMaxChoicesReached,
    multiOptions,
    buttonLoading,
    menuOptionValidated,
  };
}
