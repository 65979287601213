import RadioInput from 'components/systems/Radio';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';
import { IBaseMenuOptionResDto } from '../../../submodules/sicpama-shared';

interface SingleOptionProps {
  readonly menuOption: IBaseMenuOptionResDto;
  readonly selectedChoiceId: number;
  readonly onOptionChange: (optId: number, choiceId: number) => void;
  readonly currency: string;
}
export default function SingleOption({
  menuOption,
  selectedChoiceId,
  onOptionChange,
  currency,
}: SingleOptionProps): JSX.Element {
  const { t } = useTranslation();

  const onHandleOptionChange = useCallback(
    (choiceId: number, optionId: number) => () => {
      onOptionChange(optionId, choiceId);
    },
    [],
  );

  return (
    <div className="w-full flex flex-col mb-4 py-4 px-3 bg-white" key={menuOption.id}>
      <div className="w-full flex flex-row justify-between items-center">
        <h1 className="font-bold text-lg pb-2">{menuOption.name}</h1>
        {menuOption.minChoices > 0 && (
          <div
            className="rounded-full border py-1 px-2 border-orange-500 text-orange-500 text-sm"
            id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.IS_REQUIRED_OR_OPTIONAL_MENU_OPTION}
          >
            {t('pages.menu.required')}
          </div>
        )}
        {menuOption.minChoices === 0 && (
          <div className="rounded-full bg-gray-200 py-1 px-2 text-sm text-gray-600">
            {t('pages.menu.choice')}
          </div>
        )}
      </div>
      {menuOption.choices.map((choice) => (
        <RadioInput
          selected={selectedChoiceId === choice.id}
          text={choice.name}
          price={numberLocaleFormat(+choice.price, CURRENCY_TO_LOCALE_PRICE[currency])}
          onSelect={onHandleOptionChange(choice.id, menuOption.id)}
          key={choice.id}
        />
      ))}
    </div>
  );
}
