import { Button, Typography } from '@sicpama/core-components';
import { Widget } from '@typeform/embed-react';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useModal from '../../hooks/useModal';

import './styles.scss';

const translationPrefix = 'pages.feedback';

const FeedbackPage = (): ReactElement => {
  const { t } = useTranslation();
  const { opened, openModal } = useModal();

  history.pushState(null, '', location.href);
  window.onpopstate = (event) => {
    history.go(1);
  };

  return (
    <div className="feedback-wrapper">
      <div className="w-full h-full p-4 flex flex-col justify-center">
        <Typography className="my-[21px] text-center" weight={700}>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </Typography>
        <div
          className="my-[72px] rounded pt-[30px] pb-4 px-[18px] flex flex-col items-center w-full"
          style={{
            background: 'rgb(235, 97, 32, 0.2)',
            // boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.8)',
          }}
        >
          <img src="/images/feedback.png" className="w-[190px]" />
          <Typography className="mt-[30px] text-center" weight={500}>
            <Trans i18nKey={`${translationPrefix}.intro`} />
          </Typography>
          <img src="/images/sicpama-text-logo.png" className="w-[138px] h-full mt-4 mb-8" />
          <Button
            onClick={() => {
              openModal();
            }}
            className="text-center bg-theme mt-4"
            fullWidth={true}
            title={t(`${translationPrefix}.action`)}
            titleColor="white"
          />
        </div>
      </div>
      {opened && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-50 flex justify-center items-center">
          <Widget
            id={
              window.navigator.language?.includes('kr') || window.navigator.language?.includes('ko')
                ? 'YIOzSIZY'
                : 'GrfdBSi0'
            }
            style={{ width: '100%', height: '100%' }}
            className="my-form"
          />
        </div>
      )}
    </div>
  );
};

export default FeedbackPage;
