import ThirdPartyPasswordless from 'supertokens-auth-react/recipe/thirdpartypasswordless';
import Session from 'supertokens-auth-react/recipe/session';
import { customerService } from 'services';
import { UserContext } from 'supertokens-auth-react/lib/build/types';

export function getApiDomain(): string {
  const apiPort = process.env.REACT_APP_API_PORT || 3333;
  const apiUrl = process.env.REACT_APP_API_URL || `http://localhost:${apiPort}`;
  return apiUrl;
}

export const SuperTokensConfig = {
  appInfo: {
    appName: 'SICPAMA',
    apiDomain: getApiDomain(),
    websiteDomain: window.location.origin,
    apiBasePath: '/v1/auth',
  },
  getRedirectionURL: async (context: UserContext) => {
    if (context.action === 'SUCCESS' && context.newSessionCreated) {
      // called on a successful sign in / up. Where should the user go next?
      const redirectToPath = context.redirectToPath;
      if (redirectToPath !== undefined) {
        // we are navigating back to where the user was before they authenticated
        return redirectToPath;
      }
      return '/menus';
    }
    return '/auth';
  },
  recipeList: [
    ThirdPartyPasswordless.init({
      signInUpFeature: {
        providers: [
          ThirdPartyPasswordless.Google.init(),
          ThirdPartyPasswordless.Facebook.init(),
          {
            id: 'kakao',
            name: 'Kakao',
            getRedirectURL: () => `${getApiDomain()}/auth/callback/kakao`,
          },
        ],
      },
      contactMethod: 'EMAIL_OR_PHONE',
      style: `
                [data-supertokens~=container] {
                    display: none;
                }
            `,
      postAPIHook: async (context) => {
        if (context.action === 'THIRD_PARTY_SIGN_IN_UP') {
          await customerService.postLogin();
        }
      },
    }),
    Session.init({
      tokenTransferMethod: 'header',
    }),
  ],
};
