import styled from '@emotion/styled';
import type { ReactElement } from 'react';

const StyledLoader = styled.div<{ color?: string }>`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${(props) => props.color ?? 'var(--store-theme-color)'} transparent transparent
      transparent;

    &:nth-of-type(1) {
      animation-delay: -0.45s;
    }

    &:nth-of-type(2) {
      animation-delay: -0.3s;
    }

    &:nth-of-type(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const Loader = ({ text = '' }): ReactElement => {
  return (
    <>
      <StyledLoader>
        <div></div>
        <div></div>
        <div></div>
      </StyledLoader>
      <div className="text-center">{text}</div>
    </>
  );
};

export default Loader;

export const CenterLoader = ({ text = '' }): JSX.Element => {
  return (
    <div className="flex flex-col items-center justify-center absolute inset-0 m-auto">
      <Loader text={text} />
    </div>
  );
};
