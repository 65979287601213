/* eslint-disable @typescript-eslint/no-var-requires */
import { configureAbly } from '@ably-labs/react-hooks';
import { usePostHog } from 'posthog-js/react';
import FeedbackPage from 'pages/feedback';
import FoodCourtPage from 'pages/food-court';
import MenuList from 'pages/menu/list';
import ReadOnlyMenuList from 'pages/readonly-menu/list';
import { lazy, useEffect } from 'react';
import { Navigate, Route, Routes, useSearchParams, useNavigate } from 'react-router-dom';

import { useThemeStore, useOrderStore } from 'stores';

import 'styles/global.scss';

import SuperTokens, { SuperTokensWrapper } from 'supertokens-auth-react';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react/ui';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import { ThirdpartyPasswordlessComponentsOverrideProvider } from 'supertokens-auth-react/recipe/thirdpartypasswordless';
import { ThirdPartyPasswordlessPreBuiltUI } from 'supertokens-auth-react/recipe/thirdpartypasswordless/prebuiltui';

import SpinTheWheelV2 from './components/spin-wheel-2';
import { SuperTokensConfig } from './configs';
import './i18n';
import LoginPage from './pages/login';
import ReportError from './pages/report-error';
import { LOCAL_STORAGE_KEY } from './constants';
import DinerReceipt from './pages/receipt';
import MenuDetail from 'pages/menu-detail/MenuDetail';
import { isFoodCourtsPath } from 'utils/routing';
import { ALLOWED_BYPASS_PATH_ROUTE_NAMES } from 'constants/route.constant';

const TabPage = lazy(async () => import('./pages/tab'));
const SearchPage = lazy(async () => import('./pages/search'));
const PublicSearchPage = lazy(async () => import('./pages/public-search'));
const PaymentPage = lazy(async () => import('./pages/payment'));
const CompleteOrderPage = lazy(async () => import('./pages/complete-order'));

SuperTokens.init(SuperTokensConfig);

configureAbly({
  authUrl: `${process.env.REACT_APP_API_URL}/v1/real-time-auth/token-request`,
});

function App(): JSX.Element {
  const { getStoreDetails, getStoreById, theme } = useThemeStore();
  const { getAllOrderItemsInTheSameTable, myInfo } = useOrderStore();
  const posthog = usePostHog();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SID);

  const error = searchParams.get('error');

  if (window.location.pathname === '/auth/' && error === 'signin') {
    navigate('menus');
  }

  useEffect(() => {
    if (
      !ALLOWED_BYPASS_PATH_ROUTE_NAMES.includes(window.location.pathname) &&
      !isFoodCourtsPath(window.location.pathname)
    ) {
      if (window.location.pathname.includes('/stores/')) {
        const storeId = window.location.pathname.split('/')[2];
        getStoreById(+storeId);
      } else if (sessionId) {
        getStoreDetails();
        getAllOrderItemsInTheSameTable();
      }
    }
  }, []);

  useEffect(() => {
    if (myInfo?.id) {
      posthog.identify(myInfo?.id, {
        email: myInfo?.email,
        name: myInfo?.fullName,
      });
    } else {
      const fingerPrint = localStorage.getItem(LOCAL_STORAGE_KEY.FINGERPRINT);
      if (fingerPrint) {
        posthog.identify(fingerPrint);
      }
    }
  }, [myInfo]);

  return (
    <SuperTokensWrapper>
      <ThirdpartyPasswordlessComponentsOverrideProvider
        components={{
          ThirdPartyPasswordlessHeader_Override: ({ DefaultComponent, ...props }) => {
            return <LoginPage />;
          },
        }}
      >
        <div className="App">
          <style>
            {`
              :root {
                --store-theme-color: ${theme.color};
              } 
            `}
          </style>
          <Routes>
            {getSuperTokensRoutesForReactRouterDom(require('react-router-dom'), [
              ThirdPartyPasswordlessPreBuiltUI,
            ])}
            <Route path="/" element={<Navigate to={myInfo?.sicpamaId ? '/menus' : '/auth'} />} />
            <Route path="/menus" element={<MenuList />}></Route>
            <Route path="/menus/:id" element={<MenuDetail />}></Route>
            <Route path="/stores/:storeId" element={<ReadOnlyMenuList />}></Route>
            <Route path="/stores/:storeId/search" element={<PublicSearchPage />}></Route>
            <Route path="/payments/receipt" element={<DinerReceipt />}></Route>
            {/* <Route path="/ unsupported-device" element={<UnsupportedDevice />}></Route> */}
            <Route path="/report-error" element={<ReportError />}></Route>
            <Route
              path="/tab"
              element={
                <SessionAuth requireAuth={false}>
                  <TabPage />
                </SessionAuth>
              }
            ></Route>
            <Route
              path="/payment"
              element={
                <SessionAuth requireAuth={false}>
                  <PaymentPage />
                </SessionAuth>
              }
            ></Route>
            <Route path="/search" element={<SearchPage />}></Route>
            <Route
              path="/customer-receipt"
              element={
                <SessionAuth requireAuth={false}>
                  <CompleteOrderPage />
                </SessionAuth>
              }
            ></Route>
            <Route
              path="/spin-the-wheel"
              element={
                <SessionAuth requireAuth={false}>
                  <SpinTheWheelV2 />
                </SessionAuth>
              }
            ></Route>
            <Route path="/feedback" element={<FeedbackPage />}></Route>
            <Route path="/food-courts/:foodCourtId" element={<FoodCourtPage />}></Route>
          </Routes>
        </div>
      </ThirdpartyPasswordlessComponentsOverrideProvider>
    </SuperTokensWrapper>
  );
}

export default App;
