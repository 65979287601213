import { ANALYTICS_CSS_ID } from './analytics.constant';
import { TFunction } from 'i18next';
import { Nullable, PaymentOption } from '../submodules/sicpama-shared';

export const PAYMENT_OPTION_ID_TO_IMAGE_OPTION_ID = {
  [PaymentOption.MINE]: 'pay-mine',
  [PaymentOption.ALL]: 'on-me',
  [PaymentOption.SPIN_THE_WHEEL]: 'spin-wheel',
  [PaymentOption.ONE_OVER_N]: 'split-even',
};

export const PAYMENT_OPTION_ID_TO_LABEL = (t: TFunction): Record<PaymentOption, string> => ({
  [PaymentOption.NOT_SELECTED]: '',
  [PaymentOption.MINE]: t('common.payMine'),
  [PaymentOption.ALL]: t('common.onMe'),
  [PaymentOption.SPIN_THE_WHEEL]: t('common.spinWheel'),
  [PaymentOption.ONE_OVER_N]: t('common.splitEven'),
});

export type SelectablePaymentOption = Exclude<PaymentOption, PaymentOption.NOT_SELECTED>;

export const PAYMENT_OPTION_LIST: SelectablePaymentOption[] = [
  PaymentOption.ONE_OVER_N,
  PaymentOption.ALL,
  PaymentOption.SPIN_THE_WHEEL,
  PaymentOption.MINE,
];

interface PaymentOptionConfig {
  id: PaymentOption;
  cssId: string;
  label: string;
  enable: boolean;
  message: Nullable<string>;
}

export const PAYMENT_OPTIONS_CONFIG: Record<PaymentOption, PaymentOptionConfig> = {
  [PaymentOption.ONE_OVER_N]: {
    id: PaymentOption.ONE_OVER_N,
    cssId: ANALYTICS_CSS_ID().SPLIT_EVENLY,
    label: 'common.splitEven',
    enable: true,
    message: null,
  },
  [PaymentOption.ALL]: {
    id: PaymentOption.ALL,
    cssId: ANALYTICS_CSS_ID().ON_ME,
    label: 'common.onMe',
    enable: true,
    message: null,
  },
  [PaymentOption.SPIN_THE_WHEEL]: {
    id: PaymentOption.SPIN_THE_WHEEL,
    cssId: ANALYTICS_CSS_ID().SPIN_WHEEL,
    label: 'common.spinWheel',
    enable: true,
    message: null,
  },
  [PaymentOption.MINE]: {
    id: PaymentOption.MINE,
    cssId: ANALYTICS_CSS_ID().PAY_MINE,
    label: 'common.payMine',
    enable: true,
    message: null,
  },
  [PaymentOption.NOT_SELECTED]: {
    id: PaymentOption.NOT_SELECTED,
    cssId: ANALYTICS_CSS_ID().NOT_SELECTED,
    label: 'common.notSelected',
    enable: false,
    message: null,
  },
};
