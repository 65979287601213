import Checkbox from 'components/systems/Checkbox';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from 'utils/numberLocaleFormat';
import { IBaseMenuOptionResDto } from '../../../submodules/sicpama-shared';

interface MultiOptionProps {
  readonly menuOption: IBaseMenuOptionResDto;
  readonly selectedChoices: number[];
  readonly onChange: (optId: number, id: number) => void;
  readonly currency: string;
  readonly disabled: boolean;
}

export default function MultiOption({
  menuOption,
  selectedChoices,
  onChange,
  currency,
  disabled,
}: MultiOptionProps): JSX.Element {
  const { t } = useTranslation();
  const isChoiceSelected = useCallback(
    (id: number) => selectedChoices.includes(id),
    [selectedChoices],
  );
  const handleChange = useCallback(
    (id: number) => () => {
      onChange(menuOption.id, id);
    },
    [menuOption.id, onChange],
  );
  return (
    <div className="flex flex-col w-full px-3 py-4 mb-4 bg-white" key={menuOption.id}>
      <div className="flex flex-row items-center justify-between w-full">
        <h1 className="pb-2 text-lg font-bold">{menuOption.name}</h1>
        {menuOption.minChoices > 0 && (
          <div className="px-2 py-1 text-sm text-orange-500 border border-orange-500 rounded-full">
            {t('pages.menu.required')}
          </div>
        )}
        {menuOption.minChoices === 0 && (
          <div className="px-2 py-1 text-sm text-gray-600 bg-gray-200 rounded-full">
            {t('pages.menu.choice')}
          </div>
        )}
      </div>
      {menuOption.choices.map((choice) => (
        <Checkbox
          disabled={disabled}
          key={choice.id}
          text={choice.name}
          price={numberLocaleFormat(+choice.price, CURRENCY_TO_LOCALE_PRICE[currency])}
          selected={isChoiceSelected(choice.id)}
          onChange={handleChange(choice.id)}
        />
      ))}
    </div>
  );
}
