// documentation here: https://www.notion.so/sicpama/Carousel-Part-Menu-Recommendation-under-100ms-1-set-menu-depending-on-of-devices-scann-ee9109e3a6294a5db0d36a4885ad93d1

import {
  IBaseOrderItemResDto,
  ItemBasedRecommendMenuStructure,
  ItemBasedRecommendMenuUnit,
} from '../../../submodules/sicpama-shared';

const getRecommendationPreviousSequence = (
  recommendationSystemList: ItemBasedRecommendMenuStructure[],
  currentSequence: number,
): ItemBasedRecommendMenuStructure => {
  if (currentSequence <= 0) {
    return recommendationSystemList[0];
  }
  const recommendedSystem = recommendationSystemList.find(
    (system) => system.sequence === currentSequence,
  );
  if (recommendedSystem !== undefined) {
    return recommendedSystem;
  }
  return getRecommendationPreviousSequence(recommendationSystemList, currentSequence - 1);
};

const getRecommendationNextSequence = (
  recommendationSystemList: ItemBasedRecommendMenuStructure[],
  currentSequence: number,
): ItemBasedRecommendMenuStructure => {
  if (currentSequence >= 10) {
    return recommendationSystemList[0];
  }
  const recommendedSystem = recommendationSystemList.find(
    (system) => system.sequence === currentSequence,
  );
  if (recommendedSystem !== undefined) {
    return recommendedSystem;
  }
  return getRecommendationPreviousSequence(recommendationSystemList, currentSequence + 1);
};
const getRecommendationSystem = (
  recommendationSystemList: ItemBasedRecommendMenuStructure[],
  currentSequence: number,
): ItemBasedRecommendMenuStructure => {
  const previousRecommendedSystem = getRecommendationPreviousSequence(
    recommendationSystemList,
    currentSequence,
  );
  const nextRecommendedSystem = getRecommendationNextSequence(
    recommendationSystemList,
    currentSequence,
  );
  return currentSequence - previousRecommendedSystem.sequence <
    nextRecommendedSystem.sequence - currentSequence
    ? previousRecommendedSystem
    : nextRecommendedSystem;
};

const getRecommendedPatterns = (
  recommendationSystem: ItemBasedRecommendMenuStructure,
  currentOrderIds: number[],
): ItemBasedRecommendMenuUnit[] => {
  const recommendedPattern = recommendationSystem.recommends.filter(
    (system) =>
      system.precedentMenuIds.length === 0 ||
      system.precedentMenuIds.some((r) => currentOrderIds.includes(r)),
  );
  return recommendedPattern.sort((a, b) => b.precedentMenuIds.length - a.precedentMenuIds.length);
};

export const getMenuRecommendedIds = (
  currentOrders: IBaseOrderItemResDto[],
  recommendationSystemList: ItemBasedRecommendMenuStructure[],
  menuIds: number[],
): number[] => {
  if (recommendationSystemList.length <= 0) {
    return [];
  }
  const recommendationSystem = getRecommendationSystem(
    recommendationSystemList,
    currentOrders.length + 1,
  );
  const recommendedPatterns = getRecommendedPatterns(
    recommendationSystem,
    currentOrders.map((order) => order.menu.id),
  );
  return recommendedPatterns.length <= 0
    ? []
    : recommendedPatterns[0].subsequentMenuIds.filter((id) => menuIds.includes(id));
};
