/* eslint-disable @typescript-eslint/no-empty-function */
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import MenuRow from 'pages/menu/MenuRow';
import { Fragment, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICategoryMenuResDto } from '../../submodules/sicpama-shared';

interface Props {
  category: ICategoryMenuResDto;
  setIntersectingState: (isInWindow: boolean) => void;
}
const CategoryMenus = ({ category, setIntersectingState }: Props): JSX.Element => {
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: Array(51)
      .fill(1)
      .map((a, index) => Number((index * 0.02).toFixed(2))),
    rootMargin: '-196px 0px 0px',
  });

  useEffect(() => {
    const inWindow = (entry?.isIntersecting && entry?.intersectionRatio > 0.1) || false;
    setIntersectingState(inWindow);
  }, [entry]);

  return (
    <div ref={ref} id={`category-tab-${category.categoryId}`}>
      <h4 className="text-theme font-bold mb-2">{category.categoryName}</h4>
      <div className="flex flex-col gap-2">
        {category.menus?.map((menu) => {
          return (
            <Fragment key={menu.id}>
              <MenuRow
                onMenuClick={() => {
                  navigate(`/menus/${menu.id}`);
                }}
                menu={menu}
              />
              <div className="h-[1px] w-full bg-[#E9EBF0] shrink-0 my-2" />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default CategoryMenus;
