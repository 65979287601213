import styled from '@emotion/styled';
import { Accordion, Modal } from '@sicpama/core-components';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useModal from '../../hooks/useModal';
import { useThemeStore } from '../../stores';

const StyledAccordion = styled(Accordion)`
  .mantine-Accordion-control {
    padding: 0 0 8px 0;
    font-size: 14px !important;
  }
  .mantine-Accordion-content {
    padding: 0;
    font-size: 14px !important;
  }
  .mantine-Accordion-label {
    padding: 0;
  }

  .mantine-Accordion-content {
    color: #666;
  }

  .mantine-Accordion-item {
    border-bottom: none;
  }
`;
const Footer = (): ReactElement => {
  const { t } = useTranslation();
  const { opened: tcOpened, openModal: openTC, closeModal: closeTC } = useModal();
  const { opened: privacyOpened, openModal: openPrivacy, closeModal: closePrivacy } = useModal();
  const { store } = useThemeStore();

  const { countryCode, ownerName, name, registrationNumber, address, phoneNumber } = store;

  let translationPrefix;

  if (countryCode === 'KR') {
    translationPrefix = 'footer.sicpama-kr-info';
  } else {
    translationPrefix = 'footer.sicpama-sg-info';
  }

  return (
    <div className="w-full bg-[#f7f7f7] mt-[46px] py-8 px-6">
      <img src="/images/sicpama-text-logo.png" className="!w-[120px] h-full" />
      <div className="mt-6 mb-14 flex flex-col gap-4">
        <div className="flex gap-1 text-[#666] text-sm">
          <div className="underline cursor-pointer" onClick={openTC}>
            {t('footer.tc')}
          </div>
          <div className="">{t('common.and')}</div>
          <div className="underline cursor-pointer" onClick={openPrivacy}>
            {t('footer.privacy')}
          </div>
        </div>
        <StyledAccordion
          items={[
            {
              value: '1',
              title: t(`${translationPrefix}.title`),
              content: <Trans i18nKey={`${translationPrefix}.content`} />,
            },
          ]}
        />
        {/* Exclusive only for Korea */}
        {countryCode === 'KR' && (
          <StyledAccordion
            items={[
              {
                value: '1',
                title: `${name} 사업자 정보`,
                content: (
                  <>
                    <div>상호명: {name}</div>
                    <div>대표자명: {ownerName}</div>
                    <div>사업자등록번호: {registrationNumber}</div>
                    <div>사업장 주소: {address}</div>
                    <div>유선번호: {phoneNumber}</div>
                  </>
                ),
              },
            ]}
          />
        )}
        <div className="text-[#666] text-sm">{t('footer.shortDescription')}</div>
      </div>
      <Modal
        withCloseButton
        opened={tcOpened}
        onClose={closeTC}
        fullScreen
        title={t('footer.tc')}
        styles={() => ({
          body: {
            height: 'calc(100% - 60px)',
            overflowY: 'auto',
            padding: 0,
          },
        })}
      >
        <iframe src="https://www.sicpama.com/tc" className="w-full h-full" />
      </Modal>
      <Modal
        withCloseButton
        opened={privacyOpened}
        onClose={closePrivacy}
        fullScreen
        title={t('footer.privacy')}
        styles={() => ({
          body: {
            height: 'calc(100% - 60px)',
            overflowY: 'auto',
            padding: 0,
          },
        })}
      >
        <iframe src="https://www.sicpama.com/privacy" className="w-full h-full" />
      </Modal>
    </div>
  );
};

export default Footer;
