import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';

interface RadioInputProps {
  readonly text: string;
  readonly price?: string;
  readonly selected: boolean;
  readonly onSelect: () => void;
}

export default function RadioInput({
  text,
  selected,
  onSelect,
  price,
}: RadioInputProps): JSX.Element {
  const radioStyle = selected
    ? 'min-w-6 min-h-6 w-6 h-6 mr-2 pr-2 border-8 border-orange-500'
    : 'min-w-6 min-h-6 w-6 h-6  mr-2 pr-5 border-2 border-gray-400';

  const priceColor = price?.startsWith('-') ? 'text-blue-500' : 'text-gray-500';

  return (
    <div className="w-full flex flex-row py-2" onClick={onSelect}>
      <div className="flex flex-row w-3/4 break-all">
        <div
          className={`rounded-full ${radioStyle}`}
          id={ANALYTICS_CSS_ID().MENU_DETAIL_PAGE.SELECT_MENU_OPTION_BTN}
        ></div>
        <p className="text-gray-500">{text}</p>
      </div>
      <div className={`w-1/4 text-right ${priceColor}`}>{price}</div>
    </div>
  );
}
