import { Carousel } from '@sicpama/core-components';
import type { ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';
import { useThemeStore } from '../../stores';
import { useMenuStore } from '../../stores/menu';
import numberLocaleFormat, { CURRENCY_TO_LOCALE_PRICE } from '../../utils/numberLocaleFormat';
import { STORE_ATTRIBUTE_NAME, ANALYTICS_CSS_ID } from '../../constants';
import { useNavigate } from 'react-router-dom';
import { IBaseMenuResDto } from '../../submodules/sicpama-shared';

const DiscountCarousel = (): ReactElement => {
  const navigate = useNavigate();
  const { menusByCategories } = useMenuStore();
  const discountMenus = useMemo(() => {
    if (!menusByCategories.length) return [];
    const menus = menusByCategories.flatMap((category) => category.menus);
    const topPriorityMenus = menus.filter((menu) => menu.isTodayDiscounted || menu.priority);
    if (!topPriorityMenus.length) return menus.slice(0, 5);
    const result = topPriorityMenus;
    return result;
  }, [menusByCategories]);

  const getDiscountMenuView = (menu: IBaseMenuResDto): ReactNode => {
    return (
      <div
        className="flex flex-col w-[160px] text-sm gap-1"
        onClick={() => {
          navigate(`/menus/${menu.id}`);
        }}
        id={`${ANALYTICS_CSS_ID(menu?.id).EVENT_MENU}`}
      >
        <img
          src={menu.photo ?? 'images/no-image.png'}
          className="w-[160px] h-[160px] object-cover rounded-lg"
          style={{
            filter: 'drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.4))',
          }}
        />
        <div className="mt-1 font-bold text-[#222222] truncate ...">{menu.name}</div>
        <div className="flex gap-2">
          <span className={`${menu.isTodayDiscounted ? 'line-through' : ''} text-[#868686]`}>
            {numberLocaleFormat(+menu.price, CURRENCY_TO_LOCALE_PRICE[menu.currency])}
          </span>
          {menu.isTodayDiscounted && menu.discountPrice && (
            <span className="text-theme font-bold">
              {numberLocaleFormat(+menu.discountPrice, CURRENCY_TO_LOCALE_PRICE[menu.currency])}
            </span>
          )}
        </div>
      </div>
    );
  };

  const store = useThemeStore((state) => state.store);

  const isCarouselHidden =
    store.attributes?.find(
      (attribute) => attribute.name === STORE_ATTRIBUTE_NAME.MENU_CAROUSEL_HIDDENNESS,
    )?.value === 'true';

  if (isCarouselHidden) {
    return <></>;
  }

  return (
    <div className="w-full py-4 px-4">
      <Carousel
        items={discountMenus.map((menu) => getDiscountMenuView(menu))}
        slideSize="160px"
        slideGap="1rem"
        loop={true}
        align="start"
        withControls={false}
      />
    </div>
  );
};

export default DiscountCarousel;
