import { Button, Icon } from '@sicpama/core-components';
import clsx from 'clsx';
import { Container } from 'components';
import { CenterLoader } from 'components/loader';
import { ANALYTICS_CSS_ID } from 'constants/analytics.constant';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import debounce from 'utils/debounce';
import Divider from '../../components/divider';
import Footer from '../../components/footer';
import { LOCAL_STORAGE_KEY, STORE_ATTRIBUTE_NAME } from '../../constants';
import {
  menuSelectors,
  OrderSelectors,
  useMenuStore,
  useOrderStore,
  useThemeStore,
  useCouponStore,
  couponSelectors,
} from '../../stores';
import CategoryMenus from './CategoryMenus';
import AvailableCouponModal from './components/AvailableCouponModal/AvailableCouponModal';
import MenuCarousel from './components/MenuCarousel/MenuCarousel';
import { getRecommendedMenuIds } from './components/RecommendationAlgorithm';
import { ICategoryMenuResDto, Nullable, OrderStatus } from '../../submodules/sicpama-shared';
import OrderHistoryCarousel from './OrderHistoryCarousel';

const translationPrefix = 'pages.menu';

const MenuList: React.FC = () => {
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const location = useLocation();
  const { target } = location.state || {};

  const categories = useMenuStore((state) => state.menusByCategories);
  const getMenus = useMenuStore((state) => state.getMenus);
  const isMenuLoading = useMenuStore((state) => state.isLoading);
  const store = useThemeStore((state) => state.store);

  const { coupons, isLoading, tableOrders, myInfo } = useOrderStore();
  const storeId = useThemeStore((state) => state.store?.id);

  const storeStampCoupon = store?.coupon?.find((x) =>
    x.criteria.customer?.some((y) => y.stamps !== undefined),
  );

  const displayAvailableCoupons = useCouponStore(couponSelectors.displayAvailableCoupons);
  const setDisplayAvailableCoupons = useCouponStore((state) => state.setDisplayAvailableCoupon);
  const priceNoteAttribute = store.attributes?.find(
    (x) => x.name === STORE_ATTRIBUTE_NAME.PRICE_NOTE,
  );
  const priceNoteValue = priceNoteAttribute?.value;
  const categoryBarRef = useRef<HTMLDivElement>(null);
  const categoryListRef = useRef<HTMLDivElement[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Nullable<ICategoryMenuResDto>>(null);
  const discountCarouselIds = useMenuStore(menuSelectors.getDiscountCarouselIds);
  const menuIds = useMenuStore(menuSelectors.getBaseMenuIds);
  const defaultMenuCarouselIds = useMenuStore(menuSelectors.getDefaultCarouselIds);
  const numberOfCustomer = useOrderStore(OrderSelectors.getNumberOfCustomer);
  const recommendedSystemList = useThemeStore((state) => state.store.menuRecommended ?? []);
  const menuRecommendedCarouselIds = useMemo(
    () => getRecommendedMenuIds(numberOfCustomer, recommendedSystemList, menuIds),
    [numberOfCustomer, recommendedSystemList, menuIds],
  );

  const menuCarouselIds = useMemo(() => {
    if (menuRecommendedCarouselIds.length > 0) {
      return menuRecommendedCarouselIds;
    }
    if (discountCarouselIds.length > 0) {
      return discountCarouselIds;
    }
    return defaultMenuCarouselIds;
  }, [discountCarouselIds, defaultMenuCarouselIds, menuRecommendedCarouselIds]);

  const myDraftOrder = useMemo(() => {
    const myOrders = tableOrders.filter((item) => item.isMyself);
    return myOrders.find((item) => item.status === OrderStatus.DRAFT);
  }, [tableOrders]);

  const myTotalQuantities = myDraftOrder?.orderItems.reduce((acc, orderItem) => {
    const total = +orderItem?.menu?.quantity || 0;
    return +acc + total;
  }, 0);

  const intersectingListRef = useRef<Array<boolean | undefined>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (categories.length && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);

  useEffect(() => {
    if ((coupons.length > 0 || storeStampCoupon) && target !== 'menus-list') {
      setDisplayAvailableCoupons(true);
    }
  }, [coupons, storeStampCoupon, target]);

  const closeCouponModal = useCallback(() => {
    setDisplayAvailableCoupons(false);
  }, []);

  const setIntersectingState = useCallback(
    (categoryIndex: number) => (state: boolean) => {
      intersectingListRef.current[categoryIndex] = state;

      const intersectingList = intersectingListRef.current;

      const topIntersectCategoryIndex = intersectingList.reduce(
        (acc, cur, index) => (cur ? (acc > -1 ? Math.min(acc, index) : index) : acc),
        -1,
      );
      setSelectedCategory(categories[topIntersectCategoryIndex]);
      debounceScrollIntoView(topIntersectCategoryIndex);
    },
    [categories],
  );

  const debounceScrollIntoView = useCallback(
    debounce<[number]>((topIntersectCategoryIndex) => {
      const categoryCell = categoryListRef.current[topIntersectCategoryIndex];
      const categoryBar = categoryBarRef.current;
      if (categoryCell && categoryBar) {
        if (
          +categoryCell.offsetLeft + +categoryCell.offsetWidth >
            +categoryBar.scrollLeft + +categoryBar.offsetWidth ||
          +categoryCell.offsetLeft < +categoryBar.scrollLeft - 16
        ) {
          categoryBar.scrollTo(categoryCell.offsetLeft - 16, 0);
        }
      }
    }, 100),
    [],
  );

  const onCategoryTabClicked = useCallback((category: ICategoryMenuResDto) => {
    setSelectedCategory(category);
    const categoryContent = document.querySelector(`#category-tab-${category.categoryId}`);
    const container = containerRef.current;
    if (categoryContent && container) {
      const offsetTop = (categoryContent as HTMLDivElement).offsetTop;
      (container as HTMLDivElement).scrollTo(0, offsetTop - 56);
    }
  }, []);

  const onMenuClicked = useCallback((id: number) => {
    navigate(`/menus/${id}`);
  }, []);

  const onNext = useCallback(() => {
    navigate('/tab');
  }, [navigate]);

  useEffect(() => {
    getMenus();
  }, []);

  if (isMenuLoading) {
    return <CenterLoader />;
  }
  const sessionId = localStorage.getItem(LOCAL_STORAGE_KEY.SID)!;

  if (!sessionId) {
    return <></>;
  } else {
    return (
      <Container showSearch={true} containerRef={containerRef} canSearch={!isLoading}>
        <OrderHistoryCarousel />
        <MenuCarousel itemsIds={menuCarouselIds} onClick={onMenuClicked} />
        <Divider className="!min-h-[8px] !h-[8px] my-2" />
        <div className="px-4 py-1 overflow-hidden sticky top-[136px] z-10 shrink-0 mt-6 bg-white border-b">
          <div
            className="flex w-full gap-2 overflow-x-auto overflow-y-hidden scrollbar-hide"
            ref={categoryBarRef}
          >
            {categories.map((category, index) => (
              <div
                key={category.categoryId}
                ref={(el) => {
                  if (el !== null) {
                    categoryListRef.current[index] = el;
                  }
                }}
                onClick={() => {
                  onCategoryTabClicked(category);
                }}
                className={clsx(
                  'flex items-center justify-center min-w-[92px] h-full text-sm overflow-hidden shrink-0 px-3 py-2 rounded border border-theme',
                  selectedCategory === category
                    ? 'bg-theme font-bold text-[#ffffff]'
                    : 'bg-white text-theme',
                )}
                id={ANALYTICS_CSS_ID(category.categoryId).CATEGORY}
              >
                {category.categoryName}
              </div>
            ))}
          </div>
        </div>
        {priceNoteValue && (
          <div
            className="mx-4 mt-2 gap-2 py-2 px-4 flex items-center bg-[#F7F7F7]"
            id={ANALYTICS_CSS_ID().MENU_PAGE.NOTIFICATION_BANNER}
          >
            <Icon name="InfoCircle" type="filled" size={14} color="#999" />
            <p className="text-xs text-[#4d4d4d]">{priceNoteValue}</p>
          </div>
        )}
        <div className="flex flex-col min-h-0 p-4 mb-4 bg-white">
          {categories.map((category, index) => (
            <CategoryMenus
              key={category.categoryId}
              category={category}
              setIntersectingState={setIntersectingState(index)}
            />
          ))}
        </div>
        <div className="fixed flex flex-col items-center justify-center w-full px-4 bottom-4">
          <Button
            onClick={onNext}
            className="text-center bg-theme"
            fullWidth={true}
            leftIcon={
              <div className="rounded-full bg-white flex items-center justify-center text-theme shrink-0 min-w-[27px] min-h-[27px] text-sm">
                {myTotalQuantities || 0}
              </div>
            }
            title={t(`${translationPrefix}.actions.confirm`)}
            titleColor="white"
            loading={isLoading}
            id={ANALYTICS_CSS_ID().MENU_PAGE.GO_TO_TAB_PAGE_BTN}
          />
        </div>
        <AvailableCouponModal opened={displayAvailableCoupons} closeModal={closeCouponModal} />
        {storeStampCoupon && (
          <div className="fixed bottom-20 right-4">
            <button
              className="p-0 w-12 h-12 bg-white rounded-full active:shadow-lg mouse shadow transition ease-in duration-200 focus:outline-none flex items-center justify-center border-2 border-theme"
              onClick={() => {
                if (myInfo?.sicpamaId) {
                  setDisplayAvailableCoupons(true);
                } else {
                  navigate('/auth');
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                fill={store?.color || 'black'}
                viewBox="0 0 256 256"
              >
                <path d="M224,224a8,8,0,0,1-8,8H40a8,8,0,0,1,0-16H216A8,8,0,0,1,224,224Zm0-80v40a16,16,0,0,1-16,16H48a16,16,0,0,1-16-16V144a16,16,0,0,1,16-16h56.43L88.72,54.71A32,32,0,0,1,120,16h16a32,32,0,0,1,31.29,38.71L151.57,128H208A16,16,0,0,1,224,144ZM120.79,128h14.42l16.43-76.65A16,16,0,0,0,136,32H120a16,16,0,0,0-15.65,19.35ZM208,184V144H48v40H208Z"></path>
              </svg>
            </button>
          </div>
        )}
        {storeId === 10003 ? <div className="mb-[100px]"></div> : <Footer />}
      </Container>
    );
  }
};

export default MenuList;
